import React from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import {
    changeActiveTab,
    updateCurentInfo
} from "../../../redux/reducers/leftData";
import Config from "../../../shared/utils/config";
import { getPrefixImage } from "../../../utils";
import "./index.scss";
import ConstantsTool from "../../../shared/utils/ConstantsTool";
const LIST_FUNCTIONAL = [
    // {
    //     name: "Activities",
    //     icon: "activities-icon.svg",
    //     activeIcon: "activities-active-icon.svg",
    //     disableIcon: "activities-disable-icon.png",
    //     value: Config.RESOURCE_MODULES,
    //     listSubContent: [],
    // },
    // {
    //     name: "Templates",
    //     icon: "template-icon.png",
    //     activeIcon: "template-active-icon.png",
    //     disableIcon: "template-icon.png",
    //     value: Config.RESOURCE_TEMPLATE,
    //     listSubContent: [],
    // },
    // {
    //     name: "Interactive Elements",
    //     icon: "interactive-icon.svg",
    //     activeIcon: "interactive-action-icon.svg",
    //     value: Config.RESOURCE_SHAPES,
    //     listSubContent: [],
    //     tutorialDescription:
    //         "Visualizing your content with thousands of artworks.",
    //     tutorialVideoUrl: "",
    // },
    {
        name: "Element",
        icon: "element-icon.svg",
        activeIcon: "element-active-icon.svg",
        value: Config.RESOURCE_ELEMENT,
        listSubContent: [],
        tutorialDescription:
            "Visualizing your content with thousands of artworks.",
        tutorialVideoUrl: "",
    },
    {
        name: "Text",
        icon: "text-icon.svg",
        activeIcon: "text-active-icon.svg",
        value: Config.RESOURCE_TEXT,
        listSubContent: [],
        tutorialDescription:
            "Visualizing your content with thousands of artworks.",
        tutorialVideoUrl: "",
    },
    // {
    //     name: "Frames",
    //     icon: "frames-icon.png",
    //     activeIcon: "frames-active-icon.png",
    //     value: Config.RESOURCE_FRAMES,
    //     listSubContent: [],
    //     tutorialDescription:
    //         "Visualizing your content with thousands of artworks.",
    //     tutorialVideoUrl: "",
    // },
    // {
    //     name: "Frames",
    //     icon: "frames-icon.png",
    //     activeIcon: "frames-active-icon.png",
    //     value: Config.RESOURCE_FRAMES,
    //     listSubContent: [],
    //     tutorialDescription:
    //         "Visualizing your content with thousands of artworks.",
    //     tutorialVideoUrl: "",
    // },
    // {
    //     name: "Frames",
    //     icon: "frames-icon.png",
    //     activeIcon: "frames-active-icon.png",
    //     value: Config.RESOURCE_BACKGROUND,
    //     listSubContent: [],
    //     tutorialDescription: "",
    //     tutorialVideoUrl: "",
    // },
    {
        name: "Zones",
        icon: "zones-icon.png",
        activeIcon: "zones-icon-active.png",
        value: Config.ZONES_PAGE,
        listSubContent: [],
        tutorialDescription: "",
        tutorialVideoUrl: "",
    },
    {
        name: "Template",
        icon: "template-icon.png",
        activeIcon: "template-active-icon.png",
        value: Config.TEMPLATE_PAGE,
        listSubContent: [],
        tutorialDescription: "",
        tutorialVideoUrl: "",
    },
    // {
    //     name: "Template Variants",
    //     icon: "template-icon.png",
    //     activeIcon: "template-active-icon.png",
    //     value: Config.TEMPLATE_VARIANTS_PAGE,
    //     listSubContent: [],
    //     tutorialDescription: "",
    //     tutorialVideoUrl: "",
    // },
    {
        name: "Components",
        icon: "component-icon.png",
        activeIcon: "component-active-icon.png",
        value: Config.RESOURCE_COMPONENTS,
        listSubContent: [],
        tutorialDescription: "",
        tutorialVideoUrl: "",
    },
    {
        name: "Upload",
        icon: "upload-icon.png",
        activeIcon: "upload-active-icon.svg",
        value: Config.RESOURCE_UPLOAD,
        listSubContent: [],
        tutorialDescription: "",
        tutorialVideoUrl: "",
    },
    // {
    //     name: "Your Projects",
    //     icon: "folder-icon.png",
    //     activeIcon: "folder-icon-active.png",
    //     value: Config.RESOURCE_PROJECT,
    //     listSubContent: [],
    //     tutorialDescription: "",
    //     tutorialVideoUrl: "",
    // },
    {
        name: "Assets",
        icon: "settings.png",
        activeIcon: "settings-active.png",
        value: Config.RESOURCE_BACKGROUND,
        listSubContent: [],
        tutorialDescription: "",
        tutorialVideoUrl: "",
    },
    {
        name: "TinyTap",
        icon: "template-icon.png",
        activeIcon: "template-active-icon.png",
        value: Config.TINY_TAP_PAGE,
        listSubContent: [],
        tutorialDescription: "",
        tutorialVideoUrl: "",
    },
];
const ListTab = ({
    showTabContent,
    setShowTabContent,
}: {
    showTabContent: boolean;
    setShowTabContent: (setShowTabContent: boolean) => void;
}) => {
    const dispatch = useAppDispatch();
    const activeTab = useAppSelector((state) => state.leftDataState.activeTab);
    const userInfo = useAppSelector(
        (state) => state.userInfoResourceState.data
    );

    const {pageType, currentActivity, template, ideaTemplate} = useAppSelector(state => state.createWorksheetState);

    return (
        <div className="list-tab-container">
            {LIST_FUNCTIONAL.filter(tab => {
                if (!Object.values(ConstantsTool.TEMPLATE_TYPES).includes(pageType) && [Config.TEMPLATE_PAGE, Config.ZONES_PAGE].includes(tab.value)) {
                    return false;
                }
                if (pageType === 'template' && (template === null || template?.id === null) && tab.value !== Config.TEMPLATE_PAGE) {
                    return false;
                }
                if (tab.value === Config.TINY_TAP_PAGE && (pageType !== 'page' || ideaTemplate?.templateData?.id !== ConstantsTool.TEMPLATE_CUSTOM_BOOM_INTERACTIVE)) {
                    return false;
                }
                return true;
            }).map((el, index) => {
                let isDisable = false;
                let isActive = activeTab === el.value;
                if (
                    currentActivity?.type !==
                    Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE &&
                    el.value === Config.RESOURCE_SHAPES
                ) {
                    return (
                        <React.Fragment
                            key={el.name + "-" + index}
                        ></React.Fragment>
                    );
                }
                if (el.value == Config.RESOURCE_PROJECT && !userInfo?.email) {
                    return (
                        <React.Fragment
                            key={el.name + "-" + index}
                        ></React.Fragment>
                    );
                }

                let isDisapleInteractiveElement = false;
                if (
                    el.value === Config.RESOURCE_SHAPES &&
                    currentActivity?.type !==
                    Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE
                ) {
                    isDisapleInteractiveElement = true;
                }
                return (
                    <div
                        className={`tab-container ${isActive ? "active" : ""}`}
                        key={el.name + "-" + index}
                        onClick={() => {
                            if (!isDisapleInteractiveElement) {
                                if (!showTabContent) {
                                    setShowTabContent(true);
                                }
                                if (!isDisable) {
                                    dispatch(changeActiveTab(el.value));
                                }
                                dispatch(updateCurentInfo(null));
                            }
                        }}
                    >
                        <img
                            src={
                                getPrefixImage() +
                                "/images/functional-icon/" +
                                (isActive ? el.activeIcon : el.icon)
                            }
                            alt={"functional-icon-" + el.name}
                            style={{
                                opacity: isDisapleInteractiveElement ? 0.3 : 1,
                            }}
                        ></img>
                        <div
                            className={
                                "tab-name " +
                                (isDisable || isDisapleInteractiveElement
                                    ? "disable"
                                    : "")
                            }
                        >
                            {el.name}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
export default ListTab;
