import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../redux/hook';
import './index.scss';
import { useDispatch } from 'react-redux';
import {
    resetTempTemplatesZones,
    replaceZoneByLinkedText,
    setTemplateZoneDisplayMode, replaceLinkedTextByZone,
    addResourceItems,
    setDisabledSaveButton,
    setSeasonIconGroups
} from '../../../../redux/reducers/createWorksheet';
import ConstantsTool from '../../../../shared/utils/ConstantsTool';
import { getDataFromAccessPath, getZoneLabel, saveDesignItems } from '../../../../utils';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { getDataFromAPI, updateDataAPI } from '../../../../utils/query';
import { checkZoneContentIsImage, generateStoreThumbnailContent } from '../../../utils/worksheet.utils';
import { IResourceItemNew } from '../../../../shared/models/resourceItemNew';

export const generateLinkedCommonFields = (zone: any) => ({
    id: Math.floor(Math.random() * 999999999).toString(),
    x: zone.x,
    y: zone.y,
    width: zone.width,
    height: zone.height,
    rotate: zone.rotate,
    customZoneID: zone.id,
    fieldName: zone.fieldName,
    isLocked: zone.isLocked,
    zoneGroup: zone.zoneGroup,
});

export const makeImageZoneAlwaysInTheTopLayer = (a: IResourceItemNew, b: IResourceItemNew) => {
    if (a.type === ConstantsTool.RESOURCE_IMAGE && b.type !== ConstantsTool.RESOURCE_IMAGE) {
        return 1;
    } else if (a.type !== ConstantsTool.RESOURCE_IMAGE && b.type === ConstantsTool.RESOURCE_IMAGE) {
        return -1;
    } else {
        return 0;
    }
};

const SelectZoneDisplayMode = () => {
    const dispatch = useDispatch();

    const {template, content, templateZoneDisplayMode, pageType, pagesWorksheet, ideaTemplate, ideaAnswerSheetPage, ideaAnswerKeyPage, ideaThumbnail, tempTemplateZones} = useAppSelector(state => state.createWorksheetState);
    const resourceItems = useAppSelector(state => state.createWorksheetState.pagesWorksheet[0]?.resourceItems);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const handleChangeDisplayMode = async () => {
            dispatch(setDisabledSaveButton(true));
            if (templateZoneDisplayMode === 'zoneOnly' && resourceItems) {
                replaceLinkedTextsByZones();
            }
            dispatch(resetTempTemplatesZones({}));
            if (templateZoneDisplayMode === 'textOnly') {
                if (pageType === ConstantsTool.TEMPLATE_TYPES['storeThumbnail']) {
                    await showStoreThumbnailWithData();
                } else {
                    await addTextItems();
                }
            }
            dispatch(setDisabledSaveButton(false));
        }

        handleChangeDisplayMode();
    }, [templateZoneDisplayMode]);

    const getIdeaPageThemeTopicImage = async () => {
        if (pageType === ConstantsTool.PAGE_TYPES.page) {
            const ideaPage = ideaTemplate.ideaPage;

            try {
                const ideaPageRes = await getDataFromAPI(`resources/idea-pages/${ideaPage}/`);
                const { data: { theme } } = ideaPageRes;

                if (theme) {
                    const themeRes = await getDataFromAPI(`resources/idea-page-themes/${theme}/?additionalFields=iconUrl`);
                    const { data: { iconUrl } } = themeRes;

                    return iconUrl;
                }
            } catch (error) {
                console.error('An error occurred:', error);
            }
        }

        return null;
    };

    const showTextOnly = async() => {
        dispatch(setDisabledSaveButton(true));
        setLoading(true);
        await saveDesignItems(dispatch, pageType, pagesWorksheet[0]['resourceItems'], template?.id, ideaTemplate?.id, ideaAnswerSheetPage?.id, ideaAnswerKeyPage?.id, ideaThumbnail?.id, tempTemplateZones);
        setLoading(false);
        dispatch(setTemplateZoneDisplayMode('textOnly'));
        dispatch(setDisabledSaveButton(false));
    }

    const addTextItems = async() => {
        const zones = resourceItems.filter(item => item.fieldName);
        if (pageType === ConstantsTool.TEMPLATE_TYPES['template'] || pageType === ConstantsTool.PAGE_TYPES['page']) {
            generateLinkedTexts(zones);
        } else if (pageType === ConstantsTool.TEMPLATE_TYPES['answerSheet'] || pageType === ConstantsTool.PAGE_TYPES['answerSheet']) {
            generateAnswerSheetLinkedTexts(zones);
        } else if (pageType === ConstantsTool.PAGE_TYPES['answerKey']) {
            generateAnswerKeyLinkedTexts(zones);
        } else if (pageType === ConstantsTool.PAGE_TYPES['thumbnail']) {
            generateIdeaThumbnailLinkedTexts(zones);
        }
    }

    const generateLinkedTexts = async(zones: IResourceItemNew[]) => {
        const textItems = [];
        let accessPaths: object;
        if (templateZoneDisplayMode === 'textOnly') {
            try {
                const res = await updateDataAPI('POST', 'auto-content/generate-access-path-from-fields-list/', {
                    zones: zones.filter(zone => zone.fieldName !== 'word_count').map(zone => ({
                        id: zone.id,
                        fieldName: zone.fieldName,
                        fieldIndex: zone.fieldIndex,
                        parentField: zone.parentField,
                        parentFieldIndex: zone.parentFieldIndex,
                    })),
                    answers: pageType === 'template' ? template.answerFields : ideaTemplate.templateData.answerFields,
                    templateID: pageType === 'template' ? template.id : ideaTemplate.templateData.id,
                });
                accessPaths = res.data['accessPaths'];
            } catch (err) {
                window.alert('Error formatting fields');
            }
        }
        if (accessPaths) {
            const seasonIconGroupZones = zones.filter(zone => zone.fieldName.startsWith('season_icon') && zone.fieldName !== 'season_icon');
            if (seasonIconGroupZones.length > 0) {
                for (let i = 0; i < seasonIconGroupZones.length; i++) {
                    const res = await updateDataAPI('POST', 'auto-content/get-icon-by-season/', {
                        ideaTemplateID: ideaTemplate?.id,
                    });
                    dispatch(setSeasonIconGroups({iconName: seasonIconGroupZones[i].fieldName, iconUrl: res?.data}));
                }
            }
            for (let i = 0; i < zones.length; i++) {
                const linkedCommonFields = generateLinkedCommonFields(zones[i]);

                let zoneContent = '';
                if (templateZoneDisplayMode === 'textOnly') {
                    if (zones[i].fieldName === 'page_index') {
                        if (pageType === 'page') {
                            zoneContent = (ideaTemplate.pageIndex + 1).toString();
                        } else {
                            zoneContent = '10';
                        }
                    } else if (zones[i].fieldName === 'icon') {
                        const themeTopicUrl = getDataFromAccessPath(content, accessPaths[zones[i].id]) ?? (await getIdeaPageThemeTopicImage());
                        zoneContent = themeTopicUrl;
                    } else if (zones[i].fieldName === 'word_count') {
                        zoneContent = '###';
                    } else if (zones[i].fieldName === 'boom_linked_page' || zones[i].fieldName.startsWith('boom_drag') || zones[i].fieldName.startsWith('boom_blank')) {
                        zoneContent = ' ';
                    } else if (pageType === 'template' && zones[i].fieldName.indexOf('category') > -1 && zones[i].fieldName.indexOf('value') > -1) {
                        zoneContent = '100 + 200';
                    }
                    else if (zones[i].fieldName === ConstantsTool.CONTAINER_ZONE) {
                        zoneContent = getDataFromAccessPath(content, accessPaths[zones[i].id]) || '###';

                        if (Array.isArray(zoneContent)) {
                            if (zoneContent.every(item => typeof item === 'string')) {
                                zoneContent = zoneContent.join("~");
                            }
                        }
                    }
                    else {
                        zoneContent = getDataFromAccessPath(content, accessPaths[zones[i].id]) || '###';
                    }
                } else {
                    zoneContent = getZoneLabel(zones[i]);
                }
                if (!zoneContent) zoneContent = '';

                let zoneIsIcon = false;
                if (checkZoneContentIsImage(zoneContent) || zones[i].fieldName.startsWith(ConstantsTool.SEASON_ICON_ZONE)) {
                    zoneIsIcon = true;
                }

                if (ideaTemplate?.iconZones?.[zoneContent] || zoneIsIcon) {
                    let iconRatio;
                    if (zoneIsIcon) {
                        if (zoneContent.indexOf('whRatio') > -1 && !isNaN(parseFloat(zoneContent.substr(zoneContent.indexOf('whRatio') + 8)))) {
                            iconRatio = parseFloat(zoneContent.substr(zoneContent.indexOf('whRatio') + 8));
                        } else {
                            iconRatio = 1;
                        }
                    } else {
                        iconRatio = ideaTemplate.iconZones[zoneContent]['whRatio'];
                    }
                    const zoneRatio = linkedCommonFields.width / linkedCommonFields.height;

                    let iconWidth: number, iconHeight: number;
                    if (linkedCommonFields.width >= linkedCommonFields.height) {
                        if (iconRatio >= zoneRatio) {
                            iconWidth = linkedCommonFields.width;
                            iconHeight = iconWidth / iconRatio;
                        } else {
                            iconHeight = linkedCommonFields.height;
                            iconWidth = iconHeight * iconRatio;
                        }
                    } else {
                        if (iconRatio >= zoneRatio) {
                            iconWidth = linkedCommonFields.width;
                            iconHeight = iconWidth / iconRatio;
                        } else {
                            iconHeight = linkedCommonFields.height;
                            iconWidth = iconHeight * iconRatio;
                        }
                    }

                    let iconX = linkedCommonFields.x + (linkedCommonFields.width - iconWidth) / 2;
                    let iconY = linkedCommonFields.y + (linkedCommonFields.height - iconHeight) / 2;

                    if (ideaTemplate?.tags.includes(ConstantsTool.MUSIC_COLOR_BY_CODE_TAG)) {
                        linkedCommonFields.rotate = 0;
                        iconWidth = iconWidth * 0.7;
                        iconHeight = iconHeight * 0.7;
                        iconX += iconWidth * 0.15;
                        iconY += iconHeight * 0.15;
                    }

                    const linkedImage = {
                        ...ConstantsTool.DEFAULT_TEXT_LINKED_IMAGE,
                        ...linkedCommonFields,
                        width: iconWidth,
                        height: iconHeight,
                        x: iconX,
                        y: iconY,
                        imageAttribute: {
                            svgContent: '',
                            changeColor: {},
                            url: zoneIsIcon ? zoneContent : ideaTemplate.iconZones[zoneContent]['url'],
                        },
                    };

                    textItems.push(linkedImage);

                    dispatch(
                        replaceZoneByLinkedText({
                            zoneID: zones[i].id,
                            pageIndex: zones[i].pageIndex,
                            linkedText: linkedImage,
                            isMathFormula: false,
                        })
                    );
                } else if (zones[i].type === ConstantsTool.TYPE_RESOURCE_ZONE) {
                    const linkedText = {
                        ...ConstantsTool.DEFAULT_TEXT_LINKED_ZONE,
                        ...linkedCommonFields,
                        textAttribute: {
                            ...zones[i].textAttribute,
                            content: zoneContent,
                        },
                    };
                    if (zones[i].fieldName === ConstantsTool.CONTAINER_ZONE) {
                        linkedText['type'] = ConstantsTool.TYPE_RESOURCE_ZONE_CONTAINER;
                    }

                    dispatch(
                        replaceZoneByLinkedText({
                            zoneID: zones[i].id,
                            pageIndex: zones[i].pageIndex,
                            linkedText,
                            isMathFormula: pageType === ConstantsTool.PAGE_TYPES['page'] && ideaTemplate.mathZones && ideaTemplate.mathZones.indexOf(parseInt(zones[i].id)) !== -1
                        })
                    );

                    textItems.push(linkedText);
                }
            }

            textItems.sort((a, b) => makeImageZoneAlwaysInTheTopLayer(a, b));
        }
    }

    const generateAnswerSheetLinkedTexts = (zones: IResourceItemNew[]) => {
        const textItems = [];
        let pageIndex = 0;
        for (let i = 0; i < zones.length; i++) {
            const linkedText = {...ConstantsTool.DEFAULT_TEXT_LINKED_ZONE};
            linkedText['id'] = Math.floor(Math.random() * 999999999).toString();
            linkedText['x'] = zones[i].x;
            linkedText['y'] = zones[i].y;
            linkedText['width'] = zones[i].width;
            linkedText['height'] = zones[i].height;
            linkedText['rotate'] = zones[i].rotate;

            let textContent = '';
            if (templateZoneDisplayMode === 'textOnly') {
                if (zones[i].fieldName === 'page_index') {
                    if (pageType === ConstantsTool.PAGE_TYPES['answerSheet']) {
                        if (ideaAnswerSheetPage.ideaPageIndexes[pageIndex]) {
                            textContent = ideaAnswerSheetPage.ideaPageIndexes[pageIndex].toString();
                        } else {
                            textContent = '';
                        }
                    } else if (zones[i].fieldIndex) {
                        textContent = zones[i].fieldIndex.toString();
                    } else {
                        textContent = '';
                    }
                    pageIndex++;
                } else if (zones[i].fieldName === 'title') {
                    textContent = 'Answer Sheet';
                } else {
                    textContent = pageType === ConstantsTool.PAGE_TYPES['answerSheet'] ? '' : 'A';
                }
            } else {
                textContent = getZoneLabel(zones[i]);
            }
            linkedText['textAttribute'] = {
                ...zones[i].textAttribute,
                content: textContent,
            };
            linkedText['customZoneID'] = zones[i].id;
            textItems.push(linkedText);

            // replace zone by text
            dispatch(replaceZoneByLinkedText({
                zoneID: zones[i].id,
                pageIndex: zones[i].pageIndex,
                linkedText,
                isMathFormula: false
            }));
        }
    }

    const generateAnswerKeyLinkedTexts = (zones: IResourceItemNew[]) => {
        const textItems = [];
        let pageIndex = 0;
        for (let i = 0; i < zones.length; i++) {
            const linkedText = {...ConstantsTool.DEFAULT_TEXT_LINKED_ZONE};
            linkedText['id'] = Math.floor(Math.random() * 999999999).toString();
            linkedText['x'] = zones[i].x;
            linkedText['y'] = zones[i].y;
            linkedText['width'] = zones[i].width;
            linkedText['height'] = zones[i].height;
            linkedText['rotate'] = zones[i].rotate;

            let textContent = '';
            if (templateZoneDisplayMode === 'textOnly') {
                if (zones[i].fieldName === 'page_index') {
                    if (ideaAnswerKeyPage.ideaPageIndexes[pageIndex]) {
                        textContent = ideaAnswerKeyPage.ideaPageIndexes[pageIndex].toString();
                    } else {
                        textContent = '';
                    }
                    pageIndex++;
                } else if (zones[i].fieldName === 'title') {
                    textContent = 'Answer Key';
                } else if (zones[i].fieldName === 'page_value') {
                    if (ideaAnswerKeyPage.answerKeys[zones[i].fieldIndex - 1]) {
                        textContent = ideaAnswerKeyPage.answerKeys[zones[i].fieldIndex - 1];
                    } else {
                        textContent = '';
                    }
                } else {
                    textContent = '';
                }
            } else {
                textContent = getZoneLabel(zones[i]);
            }
            linkedText['textAttribute'] = {
                ...zones[i].textAttribute,
                content: textContent,
            };
            linkedText['customZoneID'] = zones[i].id;
            textItems.push(linkedText);

            // replace zone by text
            dispatch(replaceZoneByLinkedText({
                zoneID: zones[i].id,
                pageIndex: zones[i].pageIndex,
                linkedText,
                isMathFormula: false
            }));
        }
    }

    const generateIdeaThumbnailLinkedTexts = async(zones: IResourceItemNew[]) => {
        const textItems = [];
        const resIdea = await getDataFromAPI('product-ideas/' + ideaThumbnail.idea + '/?additionalFields=totalBundleIdeas');

        const resultItems = zones.map((zone) => {
            const linkedCommonFields = generateLinkedCommonFields(zone);

            let zoneContent = '';
            if (templateZoneDisplayMode === 'textOnly') {
                if (zone.fieldName === 'title') {
                    zoneContent = ideaThumbnail.title;
                } else if (zone.fieldName === 'subtitle1') {
                    zoneContent = ideaThumbnail.subtitle;
                } else if (zone.fieldName === 'subtitle2' && ideaThumbnail.type === 'paid') {
                    zoneContent = ideaThumbnail.subtitle2;
                } else if (zone.fieldName === 'subtitle3' && ideaThumbnail.type === 'paid') {
                    zoneContent = ideaThumbnail.subtitle3;
                } else if (zone.fieldName === 'subtitle4' && ideaThumbnail.type === 'paid') {
                    zoneContent = ideaThumbnail.subtitle4;
                } else if (zone.fieldName === 'subtitle5' && ideaThumbnail.type === 'paid') {
                    zoneContent = ideaThumbnail.subtitle5;
                } else if (zone.fieldName === 'preview1') {
                    zoneContent = ideaThumbnail.preview1Url;
                } else if (zone.fieldName === 'preview2') {
                    zoneContent = ideaThumbnail.preview2Url;
                } else if (zone.fieldName === 'preview3') {
                    zoneContent = ideaThumbnail.preview3Url;
                } else if (zone.fieldName === 'preview4') {
                    zoneContent = ideaThumbnail.preview4Url;
                } else if (zone.fieldName === 'total_pages') {
                    if (ideaThumbnail.type === 'paid') {
                        if (resIdea.data['bundle']) {
                            zoneContent = resIdea.data['totalBundleIdeas']['nbPages'].toString();
                        } else {
                            zoneContent = ideaThumbnail.totalPages + ' ' + (resIdea.data['tags'].includes(ConstantsTool.TASKCARD_TAG) ? 'CARDS' : 'PAGES');
                        }
                    } else {
                        zoneContent = 'FREE';
                    }
                } else if (zone.fieldName === 'total_ideas_in_bundle') {
                    zoneContent = resIdea.data['totalBundleIdeas']['nbIdeas'] + ' decks';
                } else if (zone.fieldName === 'icon') {
                    zoneContent = ideaThumbnail.storeIconUrl;
                } else {
                    zoneContent = '';
                }
            } else {
                zoneContent = getZoneLabel(zone);
            }

            let result = null;

            if (checkZoneContentIsImage(zoneContent) || zone.fieldName.startsWith(ConstantsTool.SEASON_ICON_ZONE)) {
                result = {
                    ...ConstantsTool.DEFAULT_TEXT_LINKED_IMAGE,
                    ...linkedCommonFields,
                    type: ConstantsTool.RESOURCE_IMAGE,
                    imageAttribute: {
                        ...zone.imageAttribute,
                        url: zoneContent,
                    },
                };

                dispatch(
                    replaceZoneByLinkedText({
                        zoneID: zone.id,
                        pageIndex: zone.pageIndex,
                        linkedText: result,
                        isMathFormula: false,
                    })
                );
            } else if (zone.type === ConstantsTool.TYPE_RESOURCE_ZONE) {
                result  = {
                    ...ConstantsTool.DEFAULT_TEXT_LINKED_ZONE,
                    ...linkedCommonFields,
                    textAttribute: {
                        ...zone.textAttribute,
                        content: zoneContent,
                    },
                };

                dispatch(
                    replaceZoneByLinkedText({
                        zoneID: zone.id,
                        pageIndex: zone.pageIndex,
                        linkedText: result,
                        isMathFormula: false,
                    })
                );
            }

            return result;
        });

        textItems.concat(resultItems.filter((item) => item !== null));
    }

    const replaceLinkedTextsByZones = () => {
        const linkedTexts = resourceItems.filter(item => item.customZoneID);
        const linkedZoneIDs = [];
        for (let i = 0; i < linkedTexts.length; i++) {
            const zone = tempTemplateZones.find(z => z.id === linkedTexts[i].customZoneID);
            if (zone) {
                linkedZoneIDs.push(zone.id);
                dispatch(
                    replaceLinkedTextByZone({
                        linkedTextID: linkedTexts[i].id,
                        pageIndex: linkedTexts[i].pageIndex,
                        zone
                    })
                );
            }
        }

        const missingZones = [];
        for (let i = 0; i < tempTemplateZones.length; i++) {
            if (linkedZoneIDs.indexOf(tempTemplateZones[i].id) === -1) {
                missingZones.push(tempTemplateZones[i]);
            }
        }

        console.log('missing zones', linkedZoneIDs, tempTemplateZones, missingZones);

        if (missingZones.length > 0) {
            dispatch(
                addResourceItems({
                    pageIndex: missingZones[0].pageIndex,
                    resourceItems: missingZones,
                })
            );
        }
    }

    const showStoreThumbnailWithData = async () => {
        setLoading(true);

        const zones = resourceItems.filter(item => item.fieldName);

        generateStoreThumbnailContent(zones, content, dispatch);

        setLoading(false);
    }

    return (
        <div className="show-test-data-button">
            {loading ? (
                <div>Loading...</div>
            ) : (
                <ButtonGroup>
                    <ToggleButton
                        id={`radio-show-zone`} type="radio" size="sm"
                        variant={templateZoneDisplayMode === 'zoneOnly' ? 'success' : 'outline-secondary'}
                        name="radio"
                        value="zoneOnly"
                        checked={templateZoneDisplayMode === 'zoneOnly'}
                        onChange={(e) => dispatch(setTemplateZoneDisplayMode('zoneOnly'))}>
                        Show Zone
                    </ToggleButton>
                    {pageType === ConstantsTool.TEMPLATE_TYPES['storeThumbnail'] ? (
                        <ToggleButton
                            id={`radio-show-thumbnail-with-test-data`} type="radio" size="sm"
                            variant={templateZoneDisplayMode === 'storeThumbnailWithTestData' ? 'success' : 'outline-secondary'}
                            name="radio"
                            value="textOnly"
                            checked={templateZoneDisplayMode === 'storeThumbnailWithTestData'}
                            onChange={showStoreThumbnailWithData}>
                            Show Store Text
                        </ToggleButton>
                    ) : (
                        <ToggleButton
                            id={`radio-show-zone-with-text`} type="radio" size="sm"
                            variant={templateZoneDisplayMode === 'textOnly' ? 'success' : 'outline-secondary'}
                            name="radio"
                            value="textOnly"
                            checked={templateZoneDisplayMode === 'textOnly'}
                            onChange={showTextOnly}>
                            Show Text
                        </ToggleButton>
                    )}
                </ButtonGroup>
            )}
        </div>
    );
};
export default SelectZoneDisplayMode;
