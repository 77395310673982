import axios from 'axios';
import { useEffect, useState } from 'react';
import { Accordion, Button, Form, ListGroup } from 'react-bootstrap';

import './styles.scss';

function TabTinyTapPage({}) {
    const [id, setID] = useState(null);
    const [iframeSource, setIframeSource] = useState(null);
    const [slides, setSlides] = useState([]);

    useEffect(() => {
        setIframeSource(null);
        setSlides([]);
    }, [id]);

    const fetchData = async() => {
        if (id) {
            const res = await axios.get('https://www.tinytap.com/store/api/album/structure/?unique_id=' + id);
            console.log(res.data);

            const iframeString = res.data['data']['album_embed_code'];
            const srcMatch = iframeString.match(/src=['"]([^'"]+)['"]/);
            const src = srcMatch ? srcMatch[1] : null;
            setIframeSource(src);

            const slides = res.data['data']['structure']['slides'];
            setSlides(slides);
        }
    }

    return (
        <div className="tab-tinytap-page">
            <Form.Group>
                <Form.Label><strong>ID</strong></Form.Label>
                <Form.Control value={id} onChange={e => setID(e.target.value)} as="textarea" rows={3} />
            </Form.Group>
            <Button className="mt-3" onClick={fetchData}>Fetch</Button>

            <hr />
            {iframeSource && (
                <div>
                    <a href={iframeSource} target="_blank" rel="noopener,noreferrer">IFRAME</a>
                </div>
            )}
            {slides.length > 0 && (
                <div className="mt-3">
                    <Accordion defaultActiveKey="0" flush>
                        {slides.map((slide, slideIndex) => (
                            <Accordion.Item eventKey={slideIndex.toString()}>
                                <Accordion.Header>Slide {slideIndex + 1}</Accordion.Header>
                                <Accordion.Body>
                                    <div className="slide-body">
                                        <img src={'https://content.tinytap.it/' + id + '/unzipped/' + slide['filePathImage']} />

                                        <ListGroup>
                                            {slide['activities'].map((activity, activityIndex) => (
                                                <ListGroup.Item key={activityIndex}>
                                                    <div>
                                                        <a href={'https://content.tinytap.it/' + id + '/unzipped/' + activity['filePathIntroRecording']} target="_blank" rel="noopener,noreferrer">Audio</a>
                                                    </div>
                                                    <div className="shapes">
                                                        {activity['shapes'].map((shape, shapeIndex) => (
                                                            <div key={shapeIndex}>
                                                                <img src={'https://content.tinytap.it/' + id + '/unzipped/' + shape['filePathThumb']} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </div>
            )}
        </div>
    );
}

export default TabTinyTapPage;