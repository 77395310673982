import { Fragment, useEffect, useState } from 'react';
import { useAppSelector } from '../../../../redux/hook';
import Config from '../../../../shared/utils/config';
import ResourceItemComponent from '../ResourceItem';
import { Button, ListGroup, Row, Col } from 'react-bootstrap';
import { getZoneLabel } from '../../../../utils';

import './styles.scss';
import { IResourceItemNew, ValidatorZone } from '../../../../shared/models/resourceItemNew';
import { useDispatch } from 'react-redux';
import { removeResourceItems, setLoading, setTemplate, updateResourceItem } from '../../../../redux/reducers/createWorksheet';
import { getTemplateFields } from '../../../../utils/template';
import { getDataFromAPI, updateDataAPI } from '../../../../utils/query';
import { hasPermission } from '../../../../resource-user/utils';
import ConstantsTool from '../../../../shared/utils/ConstantsTool';
import FieldModal from '../../../../resource-user/components/main-header/field-modal';
import LoopIcon from '@mui/icons-material/Loop';

function TabZones({
    activeTab,
    parent,
    dataTab,
    listIdBackgroundUsing,
}: {
    dataTab: any[];
    activeTab: number;
    parent: HTMLDivElement;
    listIdBackgroundUsing: string[];
}) {
    const dispatch = useDispatch();
    const resourceItems = useAppSelector(state => state.createWorksheetState.pagesWorksheet[0]?.resourceItems);
    const {template, pagesWorksheet, tempTemplateZones, activityPermissions } = useAppSelector(state => state.createWorksheetState);
    const { staffInfo } = useAppSelector((state) => state.staffInfoState);
    const [zones, setZones] = useState([]);
    const [edittingZone, setEdittingZone] = useState(null);

    useEffect(() => {
        const zones = resourceItems.filter(item => item.fieldName);
        zones.sort((a, b) => {
            if (a.parentField && b.parentField) {
                return a.parentField.localeCompare(b.parentField, undefined, { numeric: true });
            }

            if (a.fieldName !== b.fieldName) {
                return a.fieldName.localeCompare(b.fieldName, undefined, { numeric: true });
            }

            if (Math.floor(a.fieldIndex) > Math.floor(b.fieldIndex)) {
                return 1;
            } else if (Math.floor(a.fieldIndex) < Math.floor(b.fieldIndex)) {
                return -1;
            }

            return 0;
        });
        setZones(zones);
    }, [resourceItems]);

    const deleteZone = async(zone: IResourceItemNew) => {
        if (window.confirm('Are you sure to remove this zone')) {
            dispatch(
                removeResourceItems({
                    resourceIds: [zone.id],
                    pageIndex: zone.pageIndex,
                })
            );
        }
    }

    const fixZones = async() => {
        const fields = getTemplateFields(template, pagesWorksheet[0].resourceItems, tempTemplateZones);
        // fixZoneIndexes(fields);
        await fixTemplateFieldsFormat(fields);
    }

    const showZonesConfig = () => {
        console.log(template.fieldsFormat);
    }

    const copyEmptyJSON = async() => {
        dispatch(setLoading(true));
        const res = await getDataFromAPI('auto-content/get-template-empty-json/?id=' + template.id);
        navigator.clipboard.writeText(JSON.stringify(res.data));
        dispatch(setLoading(false));
    }

    const fixZoneIndexes = async(fields) => {
        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];
            if (field['nb'] === 1) {
                const zone = zones.find(zone => zone.fieldName === field['name']);
                if (zone && zone.fieldIndex !== null) {
                    dispatch(
                        updateResourceItem({
                            currentElementId: zone.id,
                            pageIndex: zone.pageIndex,
                            attrValues: [
                                {
                                    attr: "fieldIndex",
                                    value: null,
                                },
                            ],
                        })
                    );
                }
            }
        }
    }

    const fixTemplateFieldsFormat = async(fields) => {
        const resFieldsFormat = await updateDataAPI('POST', 'auto-content/generate-template-fields-format-from-fields-list/?templateID=' + template.id, fields);
        dispatch(setTemplate({...template, fieldsFormat: resFieldsFormat.data }));
        window.alert('fixed');
    }

    return (
        <div className="tab-zones-page">
            {(!template.isUsingInIdea || hasPermission(staffInfo['roles'], activityPermissions[ConstantsTool.ADD_NEW_ZONE_WHEN_TEMPLATE_IS_USING_ACTION])) && (
                <Fragment>
                    <h3>Create New Zone</h3>
                    <div className="list-resource-item">
                        {dataTab
                            .filter(resourceItem => !listIdBackgroundUsing.includes(resourceItem.id))
                            .map((resourceItem) => {
                                return (
                                    <ResourceItemComponent
                                        key={resourceItem.id}
                                        resourceItem={resourceItem}
                                        typeResource={activeTab}
                                        classNameItem={
                                            activeTab === Config.RESOURCE_TEMPLATE
                                                ? "template-resource-item"
                                                : "resource-item"
                                        }
                                    ></ResourceItemComponent>
                                );
                            })}
                    </div>
                </Fragment>
            )}

            <div className="btns">
                {/* <Button variant="secondary" size="sm" onClick={fixZones}>Fix Zones</Button> */}
                <Button variant="secondary" size="sm" onClick={showZonesConfig}>Show Zones Config</Button>
                <Button variant="secondary" size="sm" onClick={copyEmptyJSON}>Copy Empty JSON</Button>
            </div>

            <h3>Zones List</h3>
            <ListGroup className="zones-list">
                {zones.map(zone => (
                    <ListGroup.Item key={zone.id}>
                        <Row>
                            <Col className="d-flex justify-content-between">
                                <span onClick={() => setEdittingZone(zone)}>{zone.type === ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT ? 'Group ' : ''}{getZoneLabel(zone)}</span>
                                <div>
                                    {zone.shuffle && (
                                        <span className="custom-zone-suffle me-3">
                                            <LoopIcon fontSize='medium' color='success'/>
                                        </span>
                                    )}
                                    <span className="delete-zone-icon" onClick={() => deleteZone(zone)}>x</span>
                                </div>
                            </Col>
                        </Row>
                        {zone.validators && zone.validators.length > 0 && (
                            <Row>
                                <Col className="zones-list__validators">
                                    <span className="zones-list__validators__title">Zone validators: </span>
                                    <ol>
                                        {zone.validators.map((validator: ValidatorZone) => (
                                            <li>{validator.validatorName}</li>
                                        ))}
                                    </ol>
                                </Col>
                            </Row>
                        )}
                    </ListGroup.Item>
                ))}
            </ListGroup>

            {edittingZone !== null && (
                <FieldModal
                    type="answer"
                    fieldName={edittingZone?.fieldName || null}
                    fieldIndex={edittingZone?.fieldIndex || null}
                    fieldDescription={edittingZone?.fieldDescription || null}
                    parentField={edittingZone?.parentField || null}
                    parentFieldIndex={edittingZone?.parentFieldIndex || null}
                    staticValue={null}
                    fieldAnswerUserTitle={null}
                    hideModal={() => setEdittingZone(null)}
                    save={(fieldName: string, fieldIndex: number, fieldDescription: string, parentField: string, parentFieldIndex: number, staticValue: string, fieldAnswerUserTitle: string) => {
                        dispatch(
                            updateResourceItem({
                                currentElementId: edittingZone.id,
                                pageIndex: edittingZone.pageIndex,
                                attrValues: [
                                    {
                                        attr: "fieldName",
                                        value: fieldName,
                                    },
                                ],
                            })
                        );
                        setEdittingZone(null);
                    }} />
            )}
        </div>
    );
}

export default TabZones;
